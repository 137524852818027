.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: 100vh;

    padding: 0 var(--h-padding);

    max-width: 1024px;
    margin: 0 auto;
    box-sizing: border-box;
}

.main {
    flex-grow: 1;

    margin: 0 0 var(--v-padding);
}
