.heading {
    margin: 0 0 var(--v-padding);

    font-size: var(--font-size-heading1);
    line-height: 1.1em;
}

.h1 {
    font-size: var(--font-size-heading1);
}

.h2 {
    font-size: var(--font-size-heading2);
}
