.footer {
    margin: 0 0 var(--v-padding);
    display: flex;
    gap: var(--h-padding);
}

.copyright {
    margin: 0;
}

.contacts {
    list-style: none;
    padding: 0;
    margin: 0;
}
