.blockquote {
    background: var(--secondary-light-color);
    padding: var(--h-padding);
    border-left-color: var(--secondary-color);
    border-left-width: 5px;
    border-left-style: solid;
}

.blockquote p:first-child {
    margin-top: 0;
}

.blockquote p:last-child {
    margin-bottom: 0;
}
