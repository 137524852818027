:root {
    overflow: scroll;
    overflow-x: hidden;

    --h-padding: 20px;
    --v-padding: 22px;

    --xs-breakpoint: 0px;
    --sm-breakpoint: 600px;
    --md-breakpoint: 900px;
    --lg-breakpoint: 1200px;
    --xl-breakpoint: 1536px;

    --text-color: #303633;
    --error-color: #de5b54;
    --info-color: #4d94e1;
    --secondary-color: #ccc;
    --secondary-light-color: #fafafa;

    --font-size: 14px;
    --line-height: 1.6;
    --font-size-heading1: 24px;
    --font-size-heading2: 18px;
}

.body {
    min-height: 100vh;

    margin: 0;
    padding: 0;

    color: var(--text-color);
    font: var(--font-size)/var(--line-height) "Noto Sans", Arial, sans-serif;
}

@media all and (min-width: 600px) {
    :root {
        --h-padding: 20px;
        --v-padding: 25px;

        --font-size: 16px;
        --font-size-heading1: 28px;
        --font-size-heading2: 20px;
    }

    .body {
        font: var(--font-size)/var(--line-height) "Noto Sans", Arial, sans-serif;
    }
}
