.link:link {
    color: #4d94e1;
    outline-offset: 3px;
    outline-color: #4d94e1;
    text-decoration-thickness: 0.5px;
}

.link:visited {
    color: #4d94e1;
    outline-color: #4d94e1;
}

.link:hover {
    color: #4d94e1;
}

.link:active {
    color: #7fc5f5;
}
