.items {
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    flex-flow: column;
    gap: 0.5em;
}

.item {
}

@media all and (min-width: 600px) {
    .items {
        flex-flow: row;
        gap: var(--h-padding);
    }
}
