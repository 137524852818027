.header {
    display: flex;
    flex-flow: column;
    margin: 0 0 var(--v-padding);
}

.heading {
}

.time {
    color: var(--secondary-color);
}

@media all and (min-width: 600px) {
    .header {
        flex-flow: row;
        flex-wrap: wrap;
        align-items: baseline;
        gap: var(--v-padding);
    }

    .heading {
        margin: 0;
    }
}
